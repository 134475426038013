// extracted by mini-css-extract-plugin
export var tilt = "style-module--tilt--1agwU";
export var tilt3d = "style-module--tilt3d--3YNaP";
export var tiltInner = "style-module--tiltInner--1gXn5";
export var previewLight = "style-module--previewLight--2HRS7";
export var tiltDisabled = "style-module--tiltDisabled--3Svgx";
export var preview = "style-module--preview--2yA15";
export var title = "style-module--title--mhQqX";
export var by = "style-module--by--22fJq";
export var notFound = "style-module--notFound--1lgYS";
export var switchTheme = "style-module--switchTheme--3_PbA";
export var previewInner = "style-module--previewInner--F04hF";
export var shortMetadata = "style-module--shortMetadata--104I1";
export var image = "style-module--image--3902Y";
export var pig = "style-module--pig--28-KJ";
export var mints = "style-module--mints--12Qop";
export var viewer = "style-module--viewer--6uqTc";
export var viewerLink = "style-module--viewerLink--2Ditd";
export var viewerLinkActive = "style-module--viewerLinkActive--2LM6Q";